<template>
  <div class="building-form">
    <v-form ref="form" class="building-form__form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" md="6">
          <h3 class="pb-5 primary--text">{{ header }}</h3>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Name</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Name"
            v-model="form.name"
            :error-messages="form.$getError('name')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">Address</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Address"
            v-model="form.address"
            :error-messages="form.$getError('address')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">Maintenance Default Owner</label>
          <SelectUser
            flat
            solo
            required
            v-model="form.maintenance_default_owner_id"
            :error-messages="form.$getError('maintenance_default_owner_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <label class="text-field-label">Maintenance Escalation</label>
          <SelectUser
            flat
            solo
            required
            v-model="form.maintenance_escalation_id"
            :error-messages="form.$getError('maintenance_escalation_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <label class="text-field-label">Other Maintenance Emails</label>
          <NotificationEmail
            ref="notificationEmail"
            v-model="form.notification_emails"
            flat
            solo
            class="mt-2"
            hide-details="auto"
            :error-messages="form.$getError('notification_emails')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />
          <p>Please use comma (,) as a separator for email.</p>

          <v-row>
            <v-col cols="12" md="6">
              <label class="text-field-label">Default Due Days</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2"
                type="number"
                placeholder="Default Due Days"
                v-model="form.default_due_days"
                :error-messages="form.$getError('default_due_days')"
                :loading="form.$busy"
                :disabled="form.$busy"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-field-label"
                >Overdue Alert (days past due)</label
              >
              <v-text-field
                flat
                solo
                required
                class="mt-2"
                type="number"
                placeholder="Overdue Alert"
                v-model="form.overdue_alert"
                :error-messages="form.$getError('overdue_alert')"
                :loading="form.$busy"
                :disabled="form.$busy"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-checkbox
            v-model="form.is_active"
            hide-details="auto"
            label="Set as active"
            class="mt-0"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="6">
          <h3 class="pb-5 primary--text">Header Information</h3>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Banner</label>
          <BuildingBannerUpload
            ref="bannerUploader"
            class="mt-2 mb-4"
            :loading="form.$busy"
            :error="form.$getError('heading_banner')"
            :for-update="isUpdate"
            @updated="form.$clearError('heading_banner')"
            :showUploadImageBtn="hasUpdatePermission"
          />
          <p class="hint">Ideal image size 650px x 335px</p>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Title</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Title"
            v-model="form.heading_title"
            :error-messages="form.$getError('heading_title')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Subtitle</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Subtitle"
            v-model="form.heading_subtitle"
            :error-messages="form.$getError('heading_subtitle')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <div class="mt-4">
            <v-btn
              v-if="hasUpdatePermission"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Building Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import NotificationEmail from '@/components/fields/NotificationEmail'
import BuildingBannerUpload from '@/views/Home/Buildings/components/BuildingBannerUpload'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import SelectUser from '@/components/fields/SelectUser.vue'

export default {
  components: {
    SelectUser,
    NotificationEmail,
    BuildingBannerUpload,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    building: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        name: '',
        address: '',
        notification_emails: [],

        heading_banner: null,
        heading_title: '',
        heading_subtitle: '',
        maintenance_default_owner_id: '',
        maintenance_escalation_id: '',
        default_due_days: '',
        overdue_alert: '',
        is_active: false,
      }),
    }
  },

  computed: {
    ...mapState({
      createdBuilding: (state) => state.building.buildingDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return this.isUpdate ? 'Building Information' : 'Create Building'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateBuilding : this.createBuilding
    },

    isUpdate() {
      return !!this.building
    },

    hasUpdatePermission() {
      return validatePermissions(
        [PERMISSION.BUILDINGS_UPDATE],
        this.permissions
      )
    },
  },

  watch: {
    building(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createBuilding: 'building/createBuilding',
      updateBuilding: 'building/updateBuilding',
    }),

    initForm() {
      if (this.building) {
        this.form.name = this.building.name
        this.form.address = this.building.address
        this.form.notification_emails = this.building.notification_emails

        this.form.heading_banner = this.building.heading_banner
        this.form.heading_title = this.building.heading_title
        this.form.heading_subtitle = this.building.heading_subtitle

        this.form.maintenance_default_owner_id =
          this.building.maintenance_default_owner_id
        this.form.maintenance_escalation_id =
          this.building.maintenance_escalation_id
        this.form.default_due_days = this.building.default_due_days
        this.form.overdue_alert = this.building.overdue_alert
        this.form.is_active = this.building.is_active
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      /**
       * v-comboxbox value not getting updated when its still on focus
       * and submit was run
       * https://github.com/vuetifyjs/vuetify/issues/3424
       */
      if (this.$refs.notificationEmail) {
        await this.$refs.notificationEmail.refresh()
      }

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Building details successfully updated!')
          } else {
            this.showSnackbar('Building successfully created!')
            this.$router.push({
              name: 'buildings',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form values
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (this.isUpdate) return true

      if (!this.$refs.bannerUploader.hasData()) {
        this.form.$setError('heading_banner', 'Banner is required')
      }

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()
      delete form.heading_banner

      if (this.isUpdate) {
        form.id = this.building.id
        return form
      } else {
        const formData = new FormData()
        for (var field in form) {
          switch (field) {
            /* Pass arrays properly */
            case 'notification_emails':
              if (this.$refs.notificationEmail) {
                this.$refs.notificationEmail.getEmails(formData)
              }
              break
            /* ...append normally everything else */
            default:
              formData.append(field, form[field])
              break
          }
        }

        formData.append('heading_banner', this.$refs.bannerUploader.getImage())

        return formData
      }
    },
  },
}
</script>
<style lang="scss">
.building-form {
  &__form {
    max-width: 1000px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
  .hint {
    font-size: 12px; /* Adjust the font size as needed */
    color: #888; /* Choose a color for the hint text */
  }
}
</style>
